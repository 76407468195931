var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"scheme-selectors_wrapper"},[_c('VGridCol',{staticClass:"create-violation__fieldset_title"},[_vm._v(" "+_vm._s(_vm.$t('page.responsibleSelector.title'))+" ")]),_c('VGridCol',{staticClass:"create-violation__fieldset"},[_c('VGridRow',{staticClass:"create-violation__margin_bottom"},[_c('VGridCol',{staticClass:"text_header grid-col_justify-center",attrs:{"sm-4":"","xs-12":""}},[_vm._v(" "+_vm._s(_vm.$t('page.responsibleSelector.project.title'))+" ")]),_c('VGridCol',{attrs:{"sm-8":"","xs-12":""}},[_c('VInput',{staticClass:"create-violation__input",attrs:{"placeholder":_vm.$t('page.responsibleSelector.project.placeholder'),"disabled":"","type":"text"},model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}})],1)],1),_c('VGridRow',{staticClass:"create-violation__margin_bottom"},[_c('VGridCol',{staticClass:"text_header grid-col_justify-center",attrs:{"sm-4":"","xs-12":""}},[_vm._v(" "+_vm._s(_vm.$t('page.responsibleSelector.customer.title'))+" ")]),_c('VGridCol',{attrs:{"sm-8":"","xs-12":""}},[_c('VInput',{staticClass:"create-violation__input",attrs:{"placeholder":_vm.$t('page.responsibleSelector.customer.placeholder'),"disabled":"","type":"text"},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1)],1),_c('VGridRow',{staticClass:"create-violation__margin_bottom"},[_c('VGridCol',{staticClass:"text_header grid-col_justify-center",attrs:{"sm-4":"","xs-12":""}},[_vm._v(" "+_vm._s(_vm.$t('page.responsibleSelector.general.title'))+" ")]),_c('VGridCol',{attrs:{"sm-8":"","xs-12":""}},[_c('VInput',{staticClass:"create-violation__input",attrs:{"placeholder":_vm.$t('page.responsibleSelector.general.placeholder'),"disabled":"","type":"text"},model:{value:(_vm.general),callback:function ($$v) {_vm.general=$$v},expression:"general"}})],1)],1),(
                _vm.violation.statusId !== _vm.StatusEnum.Created &&
                (_vm.violation.subcontractor?.data || _vm.canCompletePrescription)
            )?_c('VGridRow',{staticClass:"create-violation__margin_bottom"},[_c('VGridCol',{staticClass:"text_header grid-col_justify-center",attrs:{"sm-4":"","xs-12":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('page.violationForm.subcontractor')))])]),_c('VGridCol',{attrs:{"sm-8":"","xs-12":""}},[_c('VMultiselect',{ref:"refSelectors",attrs:{"disabled":_vm.violation.statusId !== _vm.StatusEnum.InWork || !_vm.canCompletePrescription,"options":_vm.subcontractorList,"placeholder":_vm.$t('page.violationForm.setTypeOfRemarks'),"multiple":false,"label":"shortName","track-by":"id"},on:{"input":_vm.updateSubcontractor},model:{value:(_vm.subcontractor),callback:function ($$v) {_vm.subcontractor=$$v},expression:"subcontractor"}},[_c('template',{slot:"noOptions"},[_vm._v(" "+_vm._s(_vm.$t('page.multiselectorSlot.noDataAvailable'))+" ")]),_c('template',{slot:"noResult"},[_vm._v(" "+_vm._s(_vm.$t('page.multiselectorSlot.elementByRequestNotFound'))+" ")])],2)],1)],1):_vm._e(),(
                _vm.violation.statusId !== _vm.StatusEnum.Created &&
                (_vm.violation.subcontractor?.data || _vm.canCompletePrescription)
            )?_c('VGridRow',{staticClass:"create-violation__margin_bottom"},[_c('VGridCol',{staticClass:"text_header grid-col_justify-center",attrs:{"sm-4":"","xs-12":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('page.violationForm.executor')))])]),_c('VGridCol',{attrs:{"sm-8":"","xs-12":""}},[_c('VMultiselect',{ref:"refSelectors",attrs:{"disabled":_vm.violation.statusId !== _vm.StatusEnum.InWork || !_vm.canCompletePrescription,"options":_vm.executorList,"placeholder":_vm.$t('page.violationForm.setTypeOfRemarks'),"multiple":false,"label":"name","track-by":"id"},on:{"input":_vm.updateExecutors},model:{value:(_vm.executor),callback:function ($$v) {_vm.executor=$$v},expression:"executor"}},[_c('template',{slot:"noOptions"},[_vm._v(" "+_vm._s(_vm.$t('page.multiselectorSlot.noDataAvailable'))+" ")]),_c('template',{slot:"noResult"},[_vm._v(" "+_vm._s(_vm.$t('page.multiselectorSlot.elementByRequestNotFound'))+" ")])],2)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }